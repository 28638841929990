import React, { useEffect, useState } from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import '../scss/TestimonialSlider.scss';
import starRating from '../img/starrating.svg'
import { motion } from "motion/react"

const TestimonialSlider = () => {
  const [testimonials, setTestimonials] = useState([]);

  // Carregar os dados da API Express
  useEffect(() => {
    fetch('http://localhost:5000/api/testimonials')
      .then((response) => response.json())
      .then((data) => setTestimonials(data))
      .catch((error) => console.error('Erro ao carregar os depoimentos:', error));
  }, []);

  return (
    <div className="testimonial-slider">
      <Splide
        options={{
          type: 'loop',
          perPage: 2,
          perMove: 1,
          pagination: true,
          arrows: true,
          gap: '1rem',
          breakpoints: {
            850: {
              perPage: 1,
            },
          },
        }}
      >
        {testimonials.map((testimonial, index) => (
          <SplideSlide key={index}>
            <div initial={{ y: 200, opacity: 0 }} whileInView={{ y: 1, opacity: 1 }} transition={{ duration: .5, delay: .1,ease: [0, 0.71, 0.2, 1.01]}} className="testimonial-card flexColumn" style={{gap:'2em'}}>
              <p className="testimonial-text">{testimonial.testimonial}</p>


              <div className='flexRow' style={{ gap: '1em', }}>
                <img src={`http://localhost:5000${testimonial.image}`} alt={testimonial.name} className="testimonial-image" />
                <div className='flexColumn'>
                  <p className="testimonial-name">{testimonial.name}</p>
                  <img src={starRating} alt="" />
                </div>

              </div>
            </div>
          </SplideSlide>
        ))}
      </Splide>
    </div>
  );
};

export default TestimonialSlider;
