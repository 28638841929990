import React from 'react';
import '../scss/footer.scss';
import Logo from '../img/logo2.png'
import { motion } from "motion/react"

const Footer = () => {
    return (
        <footer className="footer">
            <div className="inner-footer flex">
                <motion.img initial={{ scale: 0, opacity: 0 }} whileInView={{ scale: 1, opacity: 1 }} transition={{ duration: .5, delay: .1,ease: [0, 0.71, 0.2, 1.01]}} src={Logo} alt="" />
            </div>
            
            <div className="waves-container">
                <svg
                    className="waves"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    viewBox="0 24 150 28"
                    preserveAspectRatio="none"
                    shapeRendering="auto"
                >
                    <defs>
                        <path
                            id="gentle-wave"
                            d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
                        />
                    </defs>
                    <g className="parallax">
                        <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7)" />
                        <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
                        <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
                        <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
                    </g>
                </svg>
            </div>

            <div className=" container" style={{ background: '#fff'}}>
                <div className='content flexRow flexColumnMobile' style={{ background: '#fff', justifyContent: 'space-between', width: '95vw', padding:'1em 0' }}>
                    <p>Lavanderia Shekinah. Todos os direitos reservados</p>
                    <p>Desenvolvido por <a href='https://wa.me//5511985827582'>PROXIMO SITE🪐</a></p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
