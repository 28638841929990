import React, {useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import '../scss/header.scss';
import Logo from "../img/logo.png"

function SideSlideMenu({ isOpen, setIsOpen }) {
  useEffect(() => {
    const sideSlide = document.querySelector('.side-slide');
    sideSlide.style.transition = 'left 500ms';
    sideSlide.style.left = isOpen ? '0px' : '-85vw';
  }, [isOpen]);

  return (
    <nav className="side-slide">
      <div> 
        <img src={Logo} Width='210px' alt="" />
      </div>
      <ul>
        <a href="/">Início</a>
        <a href="/">Serviços</a>
        <a href="/">Sobre nós</a>
        <a href="/">Fale com a gente</a>
      </ul>
      <button className="btn btnWhite">Solicitar delivery</button>

      <FontAwesomeIcon className='navclose' icon={faXmark} onClick={() => setIsOpen(false)}/>
        
    </nav>
  );
}

export default SideSlideMenu;
