import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import Logo from "../img/logo.png"
import React, { useState } from 'react';
import SideSlideMenu from "./SideSlideMenu"
import '../scss/header.scss';
import { motion } from "motion/react"




function Header() {

    const [isOpen, setIsOpen] = useState(false);

    return (

        <div style={{ display: 'flex', flexFlow: 'column nowrap', justifyContent: 'center', alignItems: 'center', minWidth: '100vw', width: '100vw', maxWidth: '100vw', position: 'absolute', zIndex: '9999' }}>
            <div className="headerContainer">
                <motion.div initial={{ y: -200, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: .5, delay: .2, ease: [0, 0.71, 0.2, 1.01] }} className="preHeader">
                    <div className="timewpp" >
                        <span><FontAwesomeIcon icon={faClock} style={{ color: '#0098DA' }} />&nbsp;&nbsp;08:00 - 18:00</span>
                        <span ><FontAwesomeIcon icon={faWhatsapp} style={{ color: '#0098DA' }} />&nbsp;&nbsp;96 98406-2933</span>
                    </div>
                    <div className="preHeaderSocialButton">
                        <a href="/"><FontAwesomeIcon icon={faInstagram} style={{ color: '#0098DA' }} /></a>
                        <a href="/"><FontAwesomeIcon icon={faFacebook} style={{ color: '#0098DA' }} /></a>
                        <a href="/"><FontAwesomeIcon icon={faWhatsapp} style={{ color: '#0098DA' }} /></a>
                    </div>
                </motion.div>

                <motion.header initial={{ y: -200, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: .5, ease: [0, 0.71, 0.2, 1.01] }} >
                    <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'center', alignItems: 'center', gap: '2em' }}>
                        <div>
                            <img src={Logo} Width='210px' alt="" />
                        </div>

                        <nav className="navHeader">
                            <a href="/">Início</a>
                            <a href="/">Serviços</a>
                            <a href="/">Sobre nós</a>
                            <a href="/">Fale com a gente</a>
                        </nav>
                    </div>

                    <div style={{ display: 'flex', flexFlow: 'row nowrap', gap: '1em', justifyContent: 'center', alignItems: 'center' }}>
                        <button className="btn btnBlue btnHeader">Agendar Delivery</button>
                        <div className="menuButton">
                            <div >
                                <FontAwesomeIcon icon={faBars} onClick={() => setIsOpen(true)} style={{fontSize:'2em', cursor: 'pointer'}}/>
                            </div>
                            <SideSlideMenu isOpen={isOpen} setIsOpen={setIsOpen} />
                        </div>
                    </div>
                </motion.header>
            </div>
        </div>
    );
}

export default Header;