import Header from './assets/components/header'
import './assets/scss/style.scss';
import "@fontsource/montserrat"
import LavanderiaSVG from "./assets/icon/LavanderiaVector.svg"
import TinturariaSVG from "./assets/icon/TinturariaVector.svg"
import "@fontsource/montserrat/600.css";
import "@fontsource/montserrat/700.css";
import Maquina from "./assets/img/maquina.png"
import Coleta from "./assets/icon/Coleta.svg"
import Entrega from "./assets/icon/Entrega.svg"
import Limpeza from "./assets/icon/Limpeza.svg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import Economia from "./assets/icon/economia.svg"
import Roupa from "./assets/icon/roupa.svg"
import Relogio from "./assets/icon/relogio.svg"
import Roupalimpa from "./assets/icon/roupalimpa.svg"
import Sustentabilidade from "./assets/icon/sustentabilidade.svg"
import Valoracessivel from "./assets/icon/valoracessivel.svg"
import imgManchou from "./assets/img/roupamanchada.jpg"
import amapaVector from "./assets/img/amapamap.svg"
import videoPlaceholder from "./assets/img/imgVideoplaceholder.jpg"
import TestimonialSlider from './assets/components/TestimonialSlider.js';
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import Footer from './assets/components/footer.js';
import Floatingbtn from './assets/components/floatingbtn.js';
import './assets/components/function/OpenLinkOnClick.js'
import { motion, useInView } from "motion/react"


const BenefitsCard = ({ imagem, titulo, descricao }) => { // Recebe as props
  return (
    <motion.div initial={{ y: 100, opacity: 0 }} whileInView={{ y: 0, opacity: 1 }} transition={{ duration: .5, ease: [0, 0.71, 0.2, 1.01] }} className='benefitsCards flexRow'>
      <img src={imagem} alt="" />
      <div className='flexColumn gapHalf'>
        <h4 style={{ color: '#fff' }}>{titulo}</h4>
        <p className='colorWhite' style={{ color: 'white' }}>
          {descricao}
        </p>
      </div>
    </motion.div>
  );
};



function App() {


  return (
    <>
      <Header />
      <Floatingbtn />

      <main>
        <div className='main-container'>
          <motion.h1 initial={{ y: 100, opacity: 0 }} whileInView={{ y: 0, opacity: 1 }} >Qualidade e conveniência, sempre pensando no seu conforto.</motion.h1>
        </div>
      </main>

      <div className='container'>
        <div className='content flexRow flexColumnReverseMobile alignItemsFlexEnd gap2'>
          <div className='cardSection flexColumnMobile'>
            <div className='cardSectionCard'>
              <motion.img initial={{ x: -100, opacity: 0 }} whileInView={{ x: 0, opacity: 1 }} src={LavanderiaSVG} width='44.26px' alt="" />
              <motion.div initial={{ x: -100, opacity: 0 }} whileInView={{ x: 0, opacity: 1 }} className='flexColumn' style={{ gap: '1em' }}>
                <h3>
                  Lavanderia
                </h3>
                <p>
                  Qualidade e cuidado garantidos para suas roupas.
                </p>
              </motion.div>

            </div>

            <div className='cardSectionCard'>
              <motion.img initial={{ x: -100, opacity: 0 }} whileInView={{ x: 0, opacity: 1 }} src={TinturariaSVG} width='42.97px' alt="" />
              <motion.div initial={{ x: -100, opacity: 0 }} whileInView={{ x: 0, opacity: 1 }} className='flexColumn' style={{ gap: '1em' }}>
                <h3>
                  Tinturaria
                </h3>
                <p>
                  Cores vibrantes e duradouras, com máxima qualidade e cuidado.
                </p>
              </motion.div>
            </div>
          </div>

          <div className='firstForm flexColumn' style={{ justifyContent: 'center', alignItems: 'center', gap: '2em' }}>
            <div className='flexRow' style={{ gap: '1em', justifyContent: 'flex-start', width: '100%' }}>
              <img src={Coleta} style={{ width: '69px', }} alt="" />
              <h2 style={{ fontSize: '2.5em', color: '#fff' }}>Delivery express</h2>
            </div>
            <form action="" className='flexColumn' style={{ gap: '1.5em', alignItems: 'flex-start', width: '100%' }}>
              <div className='flexRow flexColumnMobile input100' style={{ width: '100%', gap: '1.5em' }}>
                <input type="text" placeholder='Seu nome' name="nome" id="nameformcta" style={{ width: '50%' }} />
                <input type="number" placeholder='Whatsapp' name="whatsapp" id="whatsappformcta" style={{ width: '50%' }} />
              </div>

              <div className='flexRow flexColumnMobile input100' style={{ width: '100%', gap: '1.5em' }}>
                <input type="text" placeholder='Seu endereço' name="endereco" id="nameformcta" style={{ width: '50%' }} />

                <select name="quant" id='quant' style={{ width: '50%' }} >
                  <option value="" disabled selected>Quantidade de peças</option>
                  <option value="valor1">Valor 1</option>
                  <option value="valor2">Valor 2</option>
                  <option value="valor3">Valor 3</option>
                </select>

              </div>

              <button className="btn btnWhite">Solicitar delivery</button>
            </form>
          </div>
        </div>
      </div>

      <div className='container padding10TopBottom5Bottom'>
        <div className='content flexRow flexColumnReverseMobile' style={{ gap: '5em' }}>
          <div  className='flexColumn width55 gap2 alignItemsFlexStart justifyContentCenter'>
            <motion.div initial={{ opacity: 0, x: -300 }} whileInView={{ x: 0, opacity: 1 }} transition={{ duration: 0.5, ease: [0, 0.71, 0.2, 1.01] }} className='flexColumn gap1'> {/* Titulo */}
              <h6 className='subheadingBlue'>
                Lavagem e higienização
              </h6>
              <h2>
                Tenha mais tempo para você, deixe que <span className='textHighlight'>nós cuidamos da sua roupa</span>!
              </h2>
            </motion.div>

            <motion.div initial={{ opacity: 0, x: -300 }} whileInView={{ x: 0, opacity: 1 }} transition={{ duration: 0.5, ease: [0, 0.71, 0.2, 1.01] }} className='flexColumn gap1'>
              <h4>
                Lavagem de peças simples a delicadas
              </h4>

              <div className='flexColumn paddingLeft1 gap1'>
                <div className='flexRow gapHalf alignItemsCenter'>
                  <FontAwesomeIcon icon={faCircleCheck} className='iconCheck' /> <p> Blusas • Shorts • Calças</p>
                </div>
                <div className='flexRow gapHalf alignItemsCenter'>
                  <FontAwesomeIcon icon={faCircleCheck} className='iconCheck' /> <p>Casacos • Ternos • Vestidos delicados </p>
                </div>
              </div>
            </motion.div>
            <motion.div initial={{ opacity: 0, x: -300 }} whileInView={{ x: 0, opacity: 1 }} transition={{ duration: 0.5, ease: [0, 0.71, 0.2, 1.01] }} className='flexColumn gap1'>
              <h4>
                Lavagem e higienização
              </h4>

              <div className='flexColumn paddingLeft1 gap1'>
                <div className='flexRow gapHalf alignItemsCenter'>
                  <FontAwesomeIcon icon={faCircleCheck} className='iconCheck' /> <p>Sofás • Estofados • Pelúcias • Carrinho de bebê </p>
                </div>
                <div className='flexRow gapHalf alignItemsCenter'>
                  <FontAwesomeIcon icon={faCircleCheck} className='iconCheck' /> <p>Cadeirinha para carro • Tênis • Cortinas e Tapetes</p>
                </div>
              </div>
            </motion.div>

            <motion.button initial={{ opacity: 0, x: -300 }} whileInView={{ x: 0, opacity: 1 }} transition={{ duration: 0.5, ease: [0, 0.71, 0.2, 1.01] }} className="btn btnBlue wppbtn">Agendar Delivery</motion.button>

          </div>
          <div className='maxWidth40'>
            <motion.img initial={{ opacity: 0, y: 200 }} whileInView={{ y: 0, opacity: 1 }} transition={{ duration: 0.5, ease: [0, 0.71, 0.2, 1.01] }} src={Maquina} alt="" width='100%' className='objectFitCover' />
          </div>
        </div>
      </div>

      <div className='container backgroundDarkerBlue padding5TopBottom'>
        <div className='content flexColumn gap2_5 justifyContentCenter alignItemsCenter'>
          <div className='flexColumn gap2 justifyContentCenter alignItemsCenter'>
            <motion.h6 initial={{ y: 100, opacity: 0 }} whileInView={{ y: 0, opacity: 1 }} transition={{ duration: .5, ease: [0, 0.71, 0.2, 1.01] }} className='subheadingYellow'>
              delivery diário
            </motion.h6>

            <motion.h2 initial={{ y: 100, opacity: 0 }} whileInView={{ y: 0, opacity: 1 }} transition={{ duration: .5, delay: 0.1, ease: [0, 0.71, 0.2, 1.01] }} style={{ color: 'white', textAlign: 'center' }}>
              Muita correria e pouco tempo?
            </motion.h2>

            <motion.p initial={{ y: 100, opacity: 0 }} whileInView={{ y: 0, opacity: 1 }} transition={{ duration: .5, delay: 0.2, ease: [0, 0.71, 0.2, 1.01] }} className='textAlignCenter colorLightGray' style={{ maxWidth: '60%' }}>
              A Lavanderia Shekinah oferece atendimento qualificado e um serviço de delivery pensado exclusivamente para proporcionar praticidade e agilidade no seu dia a dia.
            </motion.p>
          </div>

          <div className='deliveryGrid'>
            <motion.div initial={{ y: 100, opacity: 0 }} whileInView={{ y: 0, opacity: 1 }} transition={{ duration: .3, ease: [0, 0.71, 0.2, 1.01] }} className='deliveryCard'>
              <img src={Coleta} alt="" />
              <h4>Coleta</h4>
              <p>Retiramos suas roupas no horário combinado, sempre garantindo eficiência e pontualidade.</p>
            </motion.div>

            <motion.div initial={{ y: 100, opacity: 0 }} whileInView={{ y: 0, opacity: 1 }} transition={{ duration: .3, delay: 0.1, ease: [0, 0.71, 0.2, 1.01] }} className='deliveryCard noBorder backgroundPrimary'>
              <img src={Limpeza} alt="" />
              <h4>Limpeza</h4>
              <p>Utilizamos produtos de alta qualidade para uma limpeza profunda, cuidadosa e eficiente.</p>
            </motion.div>

            <motion.div initial={{ y: 100, opacity: 0 }} whileInView={{ y: 0, opacity: 1 }} transition={{ duration: .3, delay: 0.2, ease: [0, 0.71, 0.2, 1.01] }} className='deliveryCard'>
              <img src={Entrega} alt="" />
              <h4>Entrega</h4>
              <p>Entregamos suas roupas limpas e perfumadas, prontas para uso, sem qualquer preocupação.</p>
            </motion.div>
          </div>

          <button className='btn btnWhite'>SOLICITAR DELIVERY AGORA</button>
        </div>
      </div>

      <div className='container'>
        <div className='content flexRow flexColumnMobile' style={{ padding: '10em 0', gap: '5em', position: 'relative', overflow: 'visible' }} >
          <div className='maxWidth50 benefitsTitle'>
            <motion.div initial={{ x: -300, opacity: 0 }} whileInView={{ x: 0, opacity: 1 }} transition={{ duration: .5, ease: [0, 0.71, 0.2, 1.01] }} className='flexColumn leftBenefits' style={{ gap: '1.5em' }}>
              <h6 className='subheadingBlue'>
                Benefícios
              </h6>
              <h1>Economize e tenha tempo para fazer o que ama!</h1>
              <p>Nosso objetivo é simplificar a sua rotina, aliando qualidade e preço justo para você.</p>
              <div className='flexRow gap1'>
                <button className='btn btnBlue'>SOLICITAR DELIVERY</button>
                <button className='btn btnBorderBlue'>SOLICITAR DELIVERY</button>
              </div>
            </motion.div>
          </div>

          <div className='benefitsGrid maxWidth50 flexColumn'>
            <BenefitsCard
              imagem={Economia}
              titulo="Economia no final do mês. "
              descricao="Você irá economizar no consumo de  água/ energia da sua casa."
            />
            <BenefitsCard
              imagem={Roupa}
              titulo="Durabilidade para a peça de roupa."
              descricao="Nos seguimos rigorosamente a lavagem de acordo com o tipo de tecido."
            />
            <BenefitsCard
              imagem={Relogio}
              titulo="Tempo livre para você."
              descricao="Bater  no tanquinho, colocar na  máquina, lavar, estender, recolher e passar, ufa!  Tenha tempo para seus hobbies, relaxar e viver!"
            />
            <BenefitsCard
              imagem={Roupalimpa}
              titulo="Peças impecáveis."
              descricao="Além de receber suas roupas limpas e passadas, você vai notar como elas mantêm aquele cheirinho irresistível!"
            />
            <BenefitsCard
              imagem={Sustentabilidade}
              titulo="Sustentabilidade"
              descricao="Faz bem pra você, faz bem pra natureza! Usamos equipamentos eficientes, produtos sustentáveis, reciclamos água e energia, e utilizamos placas solares."
            />
            <BenefitsCard
              imagem={Valoracessivel}
              titulo="Preço acessível."
              descricao="Pensando em você nossos serviços são oferecidos a um precinho amigo, garantindo qualidade sem comprometer seu orçamento."
            />
          </div>
        </div>
      </div>

      <div className='container backgroundDarkerBlue' style={{ padding: '4em 0' }}>
        <div className='content flexRow flexColumnMobile' style={{ alignItems: 'center', gap: '4em' }}>
          <motion.div initial={{ x: -300, opacity: 0 }} whileInView={{ x: 0, opacity: 1 }} transition={{ duration: .5, ease: [0, 0.71, 0.2, 1.01] }}>
            <img src={imgManchou} alt="" />
          </motion.div>

          <div className='flexColumn' style={{ gap: '2em', alignItems: 'start' }}>
            <motion.h6 initial={{ y: 100, opacity: 0 }} whileInView={{ y: 0, opacity: 1 }} transition={{ duration: .5, ease: [0, 0.71, 0.2, 1.01] }} className='subheadingBlue'>
              Tinturaria
            </motion.h6>

            <motion.h2 initial={{ y: 100, opacity: 0 }} whileInView={{ y: 0, opacity: 1 }} transition={{ duration: .5, ease: [0, 0.71, 0.2, 1.01] }} style={{ color: 'white' }}>
              Manchou aquela roupa que tanto gosta?
            </motion.h2>

            <motion.p initial={{ y: 100, opacity: 0 }} whileInView={{ y: 0, opacity: 1 }} transition={{ duration: .5, ease: [0, 0.71, 0.2, 1.01] }} className=' colorLightGray'>
              Oferecemos uma solução eficaz para revitalizar e preservar peças de roupa com valor sentimental ou emocional.
              Utilizamos técnicas especializadas de tingimento profissional, assim é possível renovar roupas desgastadas, manchadas ou desbotadas, prolongando sua vida útil.
            </motion.p>

            <motion.button initial={{ y: 100, opacity: 0 }} whileInView={{ y: 0, opacity: 1 }} transition={{ duration: .5, ease: [0, 0.71, 0.2, 1.01] }} className='btn btnWhite'>solicitar tinturaria</motion.button>
          </div>
        </div>
      </div>

      <div className='container' style={{ padding: '6em 0 10em 0', background: '#F3F3F3' }}>
        <div className='content flexRow flexColumnMobile' style={{ alignItems: 'center', justifyContent: 'space-between', maxWidth: '1000px', gap: '8em' }}>

          <motion.div initial={{ x: -250, opacity: 0 }} whileInView={{ x: 0, opacity: 1 }} transition={{ duration: .5, ease: [0, 0.71, 0.2, 1.01] }} className='flexColumn zonaAtendimento' style={{ gap: '2em', alignItems: 'start', width: '50%' }}>
            <h6 className='subheadingBlue'>
              Zona de atendimento
            </h6>

            <h1>
              Excelência no estado do Amapá
            </h1>

            <p >
              Apaixonados pelo que fazemos, atendendo com dedicação nas seguintes localidades do Estado.
            </p>

            <div className='flexRow' style={{ gap: '1em' }}>
              <div className='flexRow' style={{ gap: '.5em', alignItems: 'center' }}>
                <FontAwesomeIcon icon={faCircleCheck} className='iconCheck' /> <p>Macapá</p>
              </div>

              <div className='flexRow' style={{ gap: '.5em', alignItems: 'center' }}>
                <FontAwesomeIcon icon={faCircleCheck} className='iconCheck' /> <p>Santana</p>
              </div>
            </div>

            <button className='btn btnBlue'>SOLICITAR DELIVERY AGORA</button>
          </motion.div>

          <motion.div initial={{ scale: 0, opacity: 0 }} whileInView={{ scale: 1, opacity: 1 }} transition={{ duration: .5, delay: 0.2, ease: [0, 0.71, 0.2, 1.01] }} className='amapaDiv'>
            <img src={amapaVector} alt="" />
          </motion.div>
        </div>
      </div>

      <div className='container backgroundDarkerBlue flexColumn' style={{ paddingBottom: '4em', gap: '3em' }}>
        <div className='content flexRow flexColumnMobile' style={{ alignItems: 'center', gap: '4em', }}>
          <motion.div initial={{ y: 300, opacity: 0 }} whileInView={{ y: 1, opacity: 1 }} transition={{ duration: .5, ease: [0, 0.71, 0.2, 1.01] }} className='sobreIMG' >
            <img src={videoPlaceholder} alt="" />
          </motion.div>

          <motion.div initial={{ x: -300, opacity: 0 }} whileInView={{ x: 1, opacity: 1 }} transition={{ duration: .5, ease: [0, 0.71, 0.2, 1.01] }} className='flexColumn' style={{ gap: '2em', alignItems: 'start' }}>
            <h6 className='subheadingBlue'>
              SOBRE NÓS
            </h6>

            <h1 style={{ color: 'white' }}>
              A minha família servindo a sua
            </h1>

            <p className=' colorLightGray' style={{ textAlign: 'justify' }}>
              Em 20 de maio de 2017, demos início à nossa jornada, atendendo em casa com uma máquina de 8kg. Começamos com três clientes, maso número de pessoas solicitando nossos serviços cresceu, e logo nossa casa não era mais suficiente para a demanda. Então, inauguramos um local maior e melhor para atender nossos clientes. Três anos depois, alugamos mais um espaço para realizar a lavagem de roupas e demais serviços.
              <br /><br />
              Hoje, contamos com uma equipe dedicada que nos auxilia nos serviços da lavanderia. Temos clientes que se tornaram amigos e, acima de tudo, temos a presença constante de Deus que nunca nos abandonou. São sete anos de história, começando com uma única máquina e, hoje, com uma ampla variedade de equipamentos para atender todas as necessidades dos nossos clientes.  Cuidamos com muito carinho e dedicação de cada peça, de família para família.
            </p>
          </motion.div>
        </div>

        <div className='content flexRow flexColumnMobile CTASobre' >
          <motion.div initial={{ x: -200, opacity: 0 }} whileInView={{ x: 1, opacity: 1 }} transition={{ duration: .5, ease: [0, 0.71, 0.2, 1.01] }} className='flexColumn sobreCTA'>
            <div>
              <h2>
                Solicite o seu serviço
              </h2>
            </div>

            <div className='flexRow' style={{ gap: '1em' }} >
              <button className="btn btnBlue" style={{ width: '50%', maxWidth: '50%' }}>Agendar Delivery</button>
              <button className="btn btnBorderBlue" style={{ width: '50%', color: 'white' }}>Agendar Delivery</button>
            </div>
          </motion.div>

          <motion.div initial={{ x: 500, opacity: 0 }} whileInView={{ x: 1, opacity: 1 }} transition={{ duration: .5, ease: [0, 0.71, 0.2, 1.01] }} className='flexRow' style={{ position: 'relative', Width: '100% !important', padding: '2em', gap: '2em', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ minWidth: '100vw', maxWidth: '100vw', height: '100%', background: '#0098DA', position: 'absolute', top: 0, left: '0', zIndex: '0' }}></div>
            <div className='ctaNumber' style={{ zIndex: '1 !important', position: 'relative' }}>
              <h1 style={{ color: 'white', fontWeight: 'bold', whiteSpace: 'nowrap' }}>+<span style={{ color: '#FFAA00' }}>7 Anos</span></h1>
              <h4 style={{ color: 'white' }}>Servindo as famílias</h4>
            </div>

            <div className='ctaNumber' style={{ zIndex: '1 !important', position: 'relative' }}>
              <h1 style={{ color: 'white', whiteSpace: 'nowrap' }}>+ <span style={{ color: '#FFAA00', fontWeight: '' }}>1000</span></h1>
              <h4 style={{ color: 'white' }}>Clientes atendidos</h4>
            </div>

          </motion.div>

        </div>


        <div className='container'>
          <div className='content flexRow flexColumnMobile' style={{ gap: '1em', minHeight: '47vh' }}>
            <div className='flexRow galery1st flexColumnMobile'>
              <div className='minor1st flexColumn flexRowMobile'>
                <motion.div initial={{ x: -100, opacity: 0 }} whileInView={{ x: 1, opacity: 1 }} transition={{ duration: .5, delay: .1, ease: [0, 0.71, 0.2, 1.01] }} className='minor1stImg1'></motion.div>
                <motion.div initial={{ x: -100, opacity: 0 }} whileInView={{ x: 1, opacity: 1 }} transition={{ duration: .5, delay: .1, ease: [0, 0.71, 0.2, 1.01] }} className='minor1stImg2'></motion.div>
              </div>

              <motion.div initial={{ y: 200, opacity: 0 }} whileInView={{ y: 1, opacity: 1 }} transition={{ duration: .5, ease: [0, 0.71, 0.2, 1.01] }} className='larger1st'></motion.div>
            </div>

            <div className='galery2st flexColumn'>
              <div className='minor2st flexRow'>
                <motion.div initial={{ x: 200, opacity: 0 }} whileInView={{ x: 1, opacity: 1 }} transition={{ duration: .8, delay: .1, ease: [0, 0.71, 0.2, 1.01] }} className='minor2stImg1'></motion.div>
                <motion.div initial={{ x: 200, opacity: 0 }} whileInView={{ x: 1, opacity: 1 }} transition={{ duration: .8, delay: .2, ease: [0, 0.71, 0.2, 1.01] }} className='minor2stImg2'></motion.div>
              </div>

              <motion.div initial={{ y: 200, opacity: 0 }} whileInView={{ y: 1, opacity: 1 }} transition={{ duration: .5, delay: .1, ease: [0, 0.71, 0.2, 1.01] }} className='larger2st'></motion.div>
            </div>
          </div>
        </div>


      </div>

      <div className='container' style={{ padding: '5em 0', background: '#F2F2F2' }}>
        <div className='content flexColumn' style={{ justifyContent: 'center', gap: '3em' }}>
          <motion.div initial={{ y: -200, opacity: 0 }} whileInView={{ y: 1, opacity: 1 }} transition={{ duration: .5, delay: .1, ease: [0, 0.71, 0.2, 1.01] }} className='flexColumn' style={{ justifyContent: 'center', textAlign: 'center', gap: '1em' }}>
            <h6 className='subheadingBlue'>
              Depoimentos
            </h6>
            <h1>O que dizem nossos clientes</h1>
          </motion.div>

          <div>
            <TestimonialSlider />
          </div>
        </div>
      </div>

      <div className='container contactcontainer'>
        <div className='content flexColumnMobile flexRow' style={{ gap: '2em' }}>
          <div className='flexColumn FooterBtn'>
            <motion.div initial={{ x: -200, opacity: 0 }} whileInView={{ x: 1, opacity: 1 }} transition={{ duration: .5, delay: .1, ease: [0, 0.71, 0.2, 1.01] }} className='flexColumn' style={{ gap: '1em' }}>
              <h6 className='subheadingBlue'>
                Contato
              </h6>
              <h1>Fique Ligado e Entre em Contato Com a Gente</h1>
            </motion.div>

            <div>
              <div>
                <motion.a initial={{ x: -200, opacity: 0 }} whileInView={{ x: 1, opacity: 1 }} transition={{ duration: .5, delay: .1, ease: [0, 0.71, 0.2, 1.01] }} href="/" className='flexRow' style={{ alignItems: 'center', gap: '1em', borderBottom: '1px solid #E6E6E6', paddingBottom: '1em' }}>
                  <div style={{ padding: '1em' }}><FontAwesomeIcon icon={faWhatsapp} style={{ color: '#0098DA', fontSize: '3em' }} /></div>
                  <div className='flexColumn' style={{ gap: '.5em' }}>
                    <h4 style={{ fontSize: '1.3em' }}>Whatsapp</h4>
                    <p>96 98406-2933</p>
                  </div>
                </motion.a>
              </div>

              <div>
                <motion.a initial={{ x: -200, opacity: 0 }} whileInView={{ x: 1, opacity: 1 }} transition={{ duration: .5, delay: .1, ease: [0, 0.71, 0.2, 1.01] }} href="/" className='flexRow' style={{ alignItems: 'center', gap: '1em', borderBottom: '1px solid #E6E6E6', paddingBottom: '1em' }}>
                  <div style={{ padding: '1em' }}><FontAwesomeIcon icon={faInstagram} style={{ color: '#0098DA', fontSize: '3em' }} /></div>

                  <div className='flexColumn' style={{ gap: '.5em' }}>
                    <h4 style={{ fontSize: '1.3em' }}>Nos siga no instagram</h4>
                    <p>shekinahlavanderia</p>
                  </div>
                </motion.a>
              </div>

              <div>
                <motion.a initial={{ x: -200, opacity: 0 }} whileInView={{ x: 1, opacity: 1 }} transition={{ duration: .5, delay: .1, ease: [0, 0.71, 0.2, 1.01] }} href="/" className='flexRow' style={{ alignItems: 'center', gap: '1em', borderBottom: '1px solid #E6E6E6', paddingBottom: '1em' }}>
                  <div style={{ padding: '1em' }}><FontAwesomeIcon icon={faLocationDot} style={{ color: '#0098DA', fontSize: '3em' }} /></div>

                  <div className='flexColumn' style={{ gap: '.5em' }}>
                    <h4 style={{ fontSize: '1.3em' }}>Nos faça uma visita</h4>
                    <p>R. Leopoldo Machado, 3038a - Trem, Macapá - AP, 68901-130</p>
                  </div>
                </motion.a>
              </div>
            </div>
          </div>

          <motion.div initial={{ y: -200, opacity: 0 }} whileInView={{ y: 1, opacity: 1 }} transition={{ duration: .5, delay: .1, ease: [0, 0.71, 0.2, 1.01] }} className='contactformdiv flexColumn' >

            <div className='flexColumn' style={{ gap: '1em' }}>
              <h3 style={{ color: '#fff', fontSize: '2.5em' }}>Solicite uma coleta agora mesmo</h3>
              <p style={{ color: '#fff' }}>Solicite um delivery preenchendo as informações abaixo. Entraremos em contato o mais rápido possível.</p>
            </div>

            <form action="" className='flexColumn' style={{ gap: '1.5em', alignItems: 'flex-start' }}>
              <div className='flexRow' style={{ width: '100%', gap: '1.5em' }}>
                <input type="text" placeholder='Seu nome' name="nome" id="nameformcta" style={{ width: '50%' }} />
                <input type="number" placeholder='Whatsapp' name="whatsapp" id="whatsappformcta" style={{ width: '50%' }} />
              </div>

              <input type="text" name="email" placeholder='Endereço para retirada e entrega' id="emailformcta" style={{ width: '100%', maxWidth: '100%' }} />

              <div className='flexRow' style={{ width: '100%', gap: '1.5em' }}>
                <select className='minimal' name="service" id='service' style={{ width: '50%' }} >
                  <option value="" disabled selected>Selecione o serviço</option>
                  <option value="valor1">Valor 1</option>
                  <option value="valor2">Valor 2</option>
                  <option value="valor3">Valor 3</option>
                </select>

                <select name="quant" id='quant' style={{ width: '50%' }} >
                  <option value="" disabled selected>Quantidade de peças</option>
                  <option value="valor1">Valor 1</option>
                  <option value="valor2">Valor 2</option>
                  <option value="valor3">Valor 3</option>
                </select>



              </div>
              <textarea placeholder='Algum detalhe? nos conte!' name="" id=""></textarea>

              <button className="btn btnWhite">Solicitar delivery</button>
            </form>
          </motion.div>
        </div>
      </div>
      <motion.div initial={{ y: 200, opacity: 0 }} whileInView={{ y: 1, opacity: 1 }} transition={{ duration: .5, delay: .1, ease: [0, 0.71, 0.2, 1.01] }}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3989.8179461016766!2d-51.06566072503539!3d0.024816299974943022!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8d61e1af38fc3c41%3A0xaf38a6ce8cb09f0d!2sR.%20Leopoldo%20Machado%2C%203038%20-%20Trem%2C%20Macap%C3%A1%20-%20AP%2C%2068900-000!5e0!3m2!1spt-BR!2sbr!4v1727732780518!5m2!1spt-BR!2sbr"
          width="100%"
          height="600px"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        />
      </motion.div>

      <Footer />
    </>
  );
}

export default App;
