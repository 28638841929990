import React from 'react';
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import '../scss/floatingbtn.scss';

const Floatingbtn = () => {
    return (
        <div   class="whats-float">
            <a href="" target="_blank" className='flexRow'>
                <div className='floatingBtnIconDiv'>
                    <FontAwesomeIcon icon={faWhatsapp} className='floatingBtnIcon'/>
                </div>

                <div className='flexColumn' style={{width:'200px', justifyContent:'center', paddingLeft:'1em',  background:'#238547', gap:'.2em'}}>
                    <h5 style={{color:'white', fontSize:'1.1em', whiteSpace:'nowrap'}}>Fale com a gente</h5>
                    <p style={{color:'#ffffffbb', fontSize:'.9em', whiteSpace:'nowrap'}}>Clique aqui</p>
                </div>
            </a>
        </div>
    );
};

export default Floatingbtn;
